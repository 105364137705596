import moment from "moment";
import { sessions } from "../../config/season";

export var sessionsById = {};

export const initSessionsAgenda = () => {
  const dayNames = [
    "sunday",
    "monday",
    "tuesday",
    "wednesday",
    "thursday",
    "friday",
    "saturday",
  ];
  const momentFr = moment().locale("fr");
  for (let i = 0; i < dayNames.length; i++) {
    const d = sessions[dayNames[i]];
    if (d === undefined) continue;
    for (const s of d) {
      sessionsById[s.id] = s;
    }
  }
};
