<template>
  <section class="hero is-primary">
    <div class="hero-body">
      <p class="title">Sélectionnez une date et un cours pour vous inscrire ou vous désinscrire</p>
    </div>
  </section>
  <section class="section">
    <div class="container">
      <Calendar :max="90" />
    </div>
    <div class="container">
      <div class="box">
        <UserDailySessionList />
      </div>
    </div>
  </section>
</template>

<script>
import Calendar from "@/components/Calendar";
import UserDailySessionList from "@/components/user/DailySessionList";

export default {
  name: "UserPlanning",
  components: { Calendar, UserDailySessionList },
};
</script>
