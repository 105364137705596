<template>
  <div id="app">
    <div v-if="isAdminAuthenticated">
      <Navbar />
    </div>
    <div v-else-if="isUserAuthenticated">
      <UserNavbar />
    </div>
    <router-view />
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import Navbar from "@/components/admin/Navbar";
import UserNavbar from "@/components/user/Navbar";

export default {
  name: "App",
  components: { Navbar, UserNavbar },
  computed: {
    ...mapGetters(["isAdminAuthenticated", "isUserAuthenticated"]),
  },
};
</script>

<style></style>
