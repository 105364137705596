<template>
  <div class="secure-page-root">
    <div class="container mb-3">
      <h3 class="title tcentered">Elèves</h3>
    </div>

    <div v-if="loadingUsers" class="tcentered my-6 has-text-primary">
      <font-awesome-icon icon="spinner" size="3x" pulse />
    </div>

    <div class="container" v-if="!loadingUsers">
      <div class="columns">
        <div class="column is-4 field">
          <div class="control bottom">
            <input
              type="text"
              class="input"
              placeholder="Filtrer sur id"
              v-model="filter"
            />
          </div>
        </div>

        <div class="column is-4 field has-text-centered">
          <o-button type="button is-narrow" @click="init()">
            <font-awesome-icon icon="sync" />
          </o-button>
        </div>
      </div>
    </div>

    <div class="container" v-if="!loadingUsers">
      <table class="table is-hoverable is-fullwidth">
        <thead>
          <th class="">ID</th>
          <th class="is-hidden-mobile">Prénom</th>
          <th class="is-hidden-mobile">Nom</th>
          <th class="">H Restantes</th>
        </thead>
        <tbody key="userList">
          <UserLine
            v-for="user in filteredUsers"
            :key="user.id"
            :user="user"
            @click="showUserDetails(user)"
          />
        </tbody>
      </table>
    </div>

    <o-modal
      v-model:active="showUserDetailsModal"
      has-modal-card
      trap-focus
      :destroy-on-hide="true"
      aria-role="dialog"
      aria-modal
    >
      <UserDetails :user="selectedUser" @close="showUserDetailsModal = false">
      </UserDetails>
    </o-modal>
  </div>
</template>

<script>
import UserDetails from "@/components/admin/users/UserDetails";
import { minutesToPrettyString } from "@/services/util";
import UserLine from "@/components/admin/users/UserLine";
import { GET_All_USERS } from "@/store/action-types";

export default {
  name: "Students",
  components: { UserLine, UserDetails },
  data: function () {
    return {
      filter: undefined,
      allUsers: undefined,
      selectedUser: undefined,
      showUserDetailsModal: false,
      loadingUsers: false,
    };
  },
  methods: {
    minutesToPrettyString: minutesToPrettyString,
    init: async function () {
      this.loadingUsers = true;
      try {
        let result = await this.$store.dispatch(GET_All_USERS);
        result.sort((u1, u2) =>
          u1.id.toLowerCase().localeCompare(u2.id.toLowerCase()),
        );
        this.allUsers = result;
      } finally {
        this.loadingUsers = false;
      }
    },
    showUserDetails: function (user) {
      this.showUserDetailsModal = true;
      this.selectedUser = user;
    },
  },
  computed: {
    filteredUsers: function () {
      if (!this.allUsers) {
        return [];
      }
      if (!this.filter || this.filter.length === "") {
        return this.allUsers;
      }

      const filter = this.filter.toLowerCase();
      return this.allUsers //
        .filter(
          (u) =>
            includesIgnoreCase(u.id, filter) ||
            includesIgnoreCase(u.firstName, filter) ||
            includesIgnoreCase(u.lastName, filter),
        );
    },
  },
  created: async function () {
    await this.init();
  },
};

function includesIgnoreCase(str, searchedFragment) {
  if (!str) {
    return false;
  }
  return str.toLowerCase().includes(searchedFragment);
}
</script>

<style scoped></style>
