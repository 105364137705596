<template>
  <tr>
    <td class="col-small tcentered">
      <div @click="toggleUserPresence">
        <span v-if="loading"><font-awesome-icon icon="spinner" spin /></span>
        <span v-else>
          <font-awesome-icon
            icon="caret-left"
            class="has-text-grey-light fa-icon caret-left"
          />

          <span v-if="presence === true">
            <font-awesome-icon
              icon="check"
              class="has-text-primary has-text-weight-semibold"
            />
          </span>
          <span v-else>-</span>
          <font-awesome-icon
            icon="caret-right"
            class="has-text-grey-light fa-icon caret-right"
          />
        </span>
      </div>
    </td>
    <td>{{ userId }}</td>
    <td>{{ name }}</td>
  </tr>
</template>

<script>
import { minutesToPrettyString } from "@/services/util";
import { GET_All_USERS } from "@/store/action-types";

export default {
  name: "PresenceLine",
  props: {
    userId: String,
    presence: Boolean,
    loading: Boolean,
  },
  data: function () {
    return {
      remainingTime: "",
      name: "",
    };
  },
  methods: {
    toggleUserPresence: function () {
      if (this.loading) {
        return;
      }

      this.$emit("change", !this.presence);
    },
  },
  watch: {
    userId: {
      immediate: true,
      handler: async function (v) {
        if (!v) {
          console.log("PresenLine userId watch: no userId input?");
          return;
        }

        const allUsers = await this.$store.dispatch(GET_All_USERS);
        const user = allUsers.find((u) => u.id === v);
        if (!user) {
          console.log(
            `PresenLine userId watch: no user found in cache with userId ${v}`,
          );
          return;
        }

        this.name = `${user.firstName} ${user.lastName}`;
        const tp = user.timePaid ? user.timePaid : 0;
        const tu = user.forecastTimeUsed ? user.forecastTimeUsed : 0;
        this.remainingTime = minutesToPrettyString(tp - tu);
      },
    },
  },
};
</script>

<style scoped>
.caret-left {
  margin-right: 5px;
}

.caret-right {
  margin-left: 5px;
}
</style>
