import moment from "moment";

export const FIRST_MONTH_OF_YEAR = 8;

export const getDayNameFromDate = (d) => {
  const dayNames = [
    "sunday",
    "monday",
    "tuesday",
    "wednesday",
    "thursday",
    "friday",
    "saturday",
  ];

  return dayNames[d.getDay()];
};

export const dateFromSessionStartTime = (startTimeStr) => {
  const today = new Date();
  const timeSplits = startTimeStr.split("h");
  return new Date(
    today.getFullYear(),
    today.getMonth(),
    today.getDate(),
    timeSplits[0],
    timeSplits[1],
  );
};

export const findDayOfWeek = (date) => {
  let dayOfWeek = date.getDay() - 1;
  if (dayOfWeek < 0) dayOfWeek = 6;
  if (dayOfWeek >= 7) dayOfWeek = 0;
  return dayOfWeek;
};

export const findStartDate = (year) => {
  const startMonthAsStr = String(FIRST_MONTH_OF_YEAR).padStart(2, "0");
  return year + "-" + startMonthAsStr + "-01";
};

export const findCurrentYearStart = () => {
  const referenceTime = moment();
  let startYear = referenceTime.year();
  if (referenceTime.month() < FIRST_MONTH_OF_YEAR - 1) {
    startYear--;
  }
  return startYear;
};

export const findYearStart = (dateAsStr) => {
  const chunks = dateAsStr.split("-");
  let startYear = parseInt(chunks[0]);
  if (parseInt(chunks[1]) < FIRST_MONTH_OF_YEAR) {
    startYear--;
  }
  return startYear;
};

export const minutesToPrettyString = (minutes) => {
  if (!minutes) {
    return "0h";
  }

  const absMinutes = Math.abs(minutes);
  const partHours = Math.floor(absMinutes / 60);
  const partMinutes = (absMinutes % 60).toString().padStart(2, "0");
  const absStr = partHours + "h" + partMinutes;

  if (minutes < 0) {
    return "-" + absStr;
  } else {
    return absStr;
  }
};

export const computeConsoSummary = (userId, boughtHours, userSessions) => {
  let totalUsedTimeInMin = 0;

  if (userSessions) {
    userSessions.forEach((s) => {
      if (s.trials && s.trials.includes(userId)) return;
      if (s.karmabox && s.karmabox.includes(userId)) return;
      if (s.oneshots && s.oneshots.includes(userId)) return;
      totalUsedTimeInMin += s.durationInMin;
    });
  }

  let boughtTimeInMin = boughtHours * 60;
  let remainingTimeInMin = boughtTimeInMin - totalUsedTimeInMin;

  const summary = {};
  summary.boughtTimeInMin = boughtTimeInMin;
  summary.totalUsedTimeInMin = totalUsedTimeInMin;
  summary.remainingTimeInMin = remainingTimeInMin;
  return summary;
};
