<template>
  <div class="modal-card" style="width: auto">
    <header class="modal-card-head">
      <p class="modal-card-title">Reset du mot de passe</p>
    </header>

    <section class="modal-card-body">
      <o-field label="Email">
        <o-input
          type="email"
          v-model="email"
          placeholder="gisele.halimi@exemple.fr"
          maxlength="128"
          required
        >
        </o-input>
      </o-field>
    </section>

    <footer class="modal-card-foot">
      <o-button variant="primary" @click="reset">Valider</o-button>
    </footer>
  </div>
</template>

<script>
import { fResetPassword } from "@/services/firebase-auth";

export default {
  name: "ResetPassword",
  data() {
    return {
      email: "",
    };
  },
  methods: {
    async reset() {
      try {
        await fResetPassword(this.email);
        this.$emit("success");
      } catch (err) {
        console.log(`error in resetPassword(): ${err}`);
        this.$emit("error");
      }
    },
  },
};
</script>
