import { getUser, getTeacher } from "@/services/firestore";
import { UPDATE_USER } from "@/store/mutation-types";

export async function loginRedirect(email) {
  try {
    const userData = await getUser(email);
    let teacherData;
    if (userData !== undefined) {
      this.$store.commit(UPDATE_USER, {
        displayName: `${userData.firstName} ${userData.lastName}`,
        email: email,
        timePaid: userData.timePaid,
        timeUsed: userData.timeUsed,
        forecastTimeUsed: userData.forecastTimeUsed,
        isAdmin: false,
      });
      this.$router.push("/user");
      return true;
    } else if ((teacherData = await getTeacher(email)) != undefined) {
      this.$store.commit(UPDATE_USER, {
        displayName: teacherData.firstName,
        email: email,
        isAdmin: true,
      });
      this.$router.push("/admin");
      return true;
    } else {
      console.log(`loginRedirect(): ${email} not allowed to access webapp`);
      return false;
    }
  } catch (err) {
    console.log(`error in loginRedirect(): ${err}`);
    throw err;
  }
}
