import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { library } from "@fortawesome/fontawesome-svg-core";
import {
  faPen,
  faSpinner,
  faCheck,
  faCaretLeft,
  faCaretRight,
  faCaretUp,
  faCaretDown,
  faUserPlus,
  faSearchPlus,
  faTimesCircle,
  faSync,
  faPlusCircle,
  faCalendar,
  faAngleLeft,
  faAngleRight,
  faEye,
  faEyeSlash,
  faTimes,
  faFaceSmile,
} from "@fortawesome/free-solid-svg-icons";

library.add(
  faPen,
  faSpinner,
  faCheck,
  faCaretLeft,
  faCaretRight,
  faCaretUp,
  faCaretDown,
  faUserPlus,
  faSearchPlus,
  faTimesCircle,
  faSync,
  faPlusCircle,
  faCalendar,
  faAngleLeft,
  faAngleRight,
  faEye,
  faEyeSlash,
  faTimes,
  faFaceSmile,
);

export default FontAwesomeIcon;
