<template>
  <div class="columns is-centered">
    <div class="column is-half box mt-5">
      <p class="has-text-centered">
        <strong class="has-text-primary is-size-3"
          >Le chien tête en bas Rennes</strong
        >
      </p>
      <p class="has-text-centered has-text-primary is-size-4">Enregistrement</p>
      <div class="m-3">
        <o-field
          label="Email"
          :variant="emailInputVariant"
          :message="emailInputMessage"
        >
          <o-input
            type="email"
            v-model="email"
            placeholder="gisele.halimi@exemple.fr"
            maxlength="128"
          >
          </o-input>
        </o-field>
        <o-field label="Password">
          <o-input
            type="password"
            v-model="password"
            placeholder="mongrandsecret"
            password-reveal
          >
          </o-input>
        </o-field>
        <o-loading v-model:active="isLoading">
          <o-icon icon="sync" size="large" spin> </o-icon>
        </o-loading>
        <button class="button is-primary" @click="register">
          S'enregistrer
        </button>
        <div class="buttons is-right">
          <router-link to="/login" class="button is-primary is-outlined">
            <span class="icon">
              <font-awesome-icon icon="angle-left" />
            </span>
            <span> login </span>
          </router-link>
        </div>
      </div>
    </div>
  </div>

  <div class="columns is-centered">
    <div class="column is-half">
      <o-notification variant="primary" closable>
        Veuillez vous enregistrer avec
        <strong>
          l'adresse email que vous avez utilisée lors de l'inscription à
          l'association.
        </strong>
        L'enregistrement avec toute autre adresse email ne fonctionnera pas.
      </o-notification>
    </div>
  </div>
</template>

<script>
import { loginRedirect } from "@/services/login";
import { fRegister, fDeleteUser } from "@/services/firebase-auth";

export default {
  name: "Register",
  data() {
    return {
      email: "",
      password: "",
      emailInputVariant: "",
      emailInputMessage: "",
      isLoading: false,
    };
  },
  methods: {
    async register() {
      try {
        console.log(`register() for email ${this.email}`);
        this.emailInputVariant = "";
        this.emailInputMessage = "";
        this.isLoading = true;
        await fRegister(this.email, this.password);
        if ((await loginRedirect.call(this, this.email)) == false) {
          /* email is not in users nor in teachers collection */
          /* we delete from firebase-auth user we just registered */
          this.emailInputVariant = "danger";
          this.emailInputMessage =
            "L'adresse email doit être celle renseignée lors de l'inscription";
          await fDeleteUser();
        }
        this.isLoading = false;
      } catch (err) {
        console.log(`error in register(): ${err}`);
        this.emailInputVariant = "danger";
        this.emailInputMessage =
          "Erreur d'enregistrement: vous êtes probablement déjà enregistrés";
        this.isLoading = false;
      }
    },
  },
};
</script>

<style scoped></style>
