<template>
  <div class="modal-card">
    <header class="modal-card-head">
      <p class="modal-card-title">{{ user.firstName }} {{ user.lastName }}</p>
    </header>
    <section class="modal-card-body">
      <p>{{ user.id }}</p>

      <table class="table v-spaced">
        <tbody>
          <tr>
            <td class="">Heures achetées</td>
            <td>{{ hoursBought }}</td>
          </tr>
          <tr>
            <td class="">Heures utilisées</td>
            <td>{{ hoursConsumed }}</td>
          </tr>
          <tr>
            <td class="">Heures restantes</td>
            <td>{{ hoursRemaining }}</td>
          </tr>
        </tbody>
      </table>
    </section>
  </div>
</template>

<script>
import * as util from "@/services/util";

export default {
  name: "UserDetails",
  props: {
    user: Object,
  },
  computed: {
    hoursBought: function () {
      return util.minutesToPrettyString(this.user.timePaid);
    },
    hoursConsumed: function () {
      return util.minutesToPrettyString(this.user.timeUsed);
    },
    hoursRemaining: function () {
      const tp = this.user.timePaid ? this.user.timePaid : 0;
      const tu = this.user.timeUsed ? this.user.timeUsed : 0;
      return util.minutesToPrettyString(tp - tu);
    },
  },
};
</script>

<style scoped></style>
