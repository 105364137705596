<template>
  <span v-if="dayOff">
    <strong class="has-text-danger-dark mt-5"> Pas de cours </strong>
  </span>
  <table v-else class="table is-hoverable is-fullwidth">
    <thead>
      <th colspan="2" class="is-capitalized">{{ dateAsStr }}</th>
      <th class="tcentered">Inscrits</th>
      <th class="tcentered" />
    </thead>
    <tbody>
      <tr v-for="s in dailySessions" :key="s.id">
        <td>
          <strong class="has-text-primary">{{ s.startTime }}</strong>
        </td>
        <td>{{ s.name }}</td>
        <td class="tcentered">{{ s.enrolledUsers }}</td>
        <td class="tcentered">
          <span v-if="!s.validated">
            <router-link class="button is-primary" :to="s.linkStr">
              <span class="icon"><font-awesome-icon icon="pen" /></span>
            </router-link>
          </span>
          <span v-else>
            <router-link class="button is-success is-outlined" :to="s.linkStr">
              <span class="icon"><font-awesome-icon icon="check" /></span>
            </router-link>
          </span>
        </td>
      </tr>
    </tbody>
  </table>
</template>

<script>
import { mapState } from "vuex";
import moment from "moment";
import { sessions } from "../../../../config/season";
import { loadDaySessions } from "@/services/firestore";
import { getDayNameFromDate } from "@/services/util";

export default {
  name: "DailySessionList",
  props: {},
  data: function () {
    return {
      daySessionsById: {},
      dayOff: false,
    };
  },
  computed: {
    dateAsStr: function () {
      return moment(this.date).format("dddd D MMMM");
    },
    dailySessions: function () {
      const daySessions = sessions[getDayNameFromDate(this.date)];
      if (daySessions === undefined) return {};

      const enrichedSessions = JSON.parse(JSON.stringify(daySessions));

      let dateAsStr = moment(this.date).format("MM-DD");
      for (const s of enrichedSessions) {
        s.linkStr = `/admin/session/${s.id}/${dateAsStr}`;
        s.enrolledUsers = 0;

        const sessionData = this.daySessionsById[s.id];
        if (sessionData) {
          s.enrolledUsers =
            sessionData.users !== undefined ? sessionData.users.length : 0;
          s.validated = sessionData.adminUpdatedAt !== undefined ? true : false;
        }
      }

      return enrichedSessions;
    },
    ...mapState([
      // map this.date to store.state.date
      "date",
    ]),
  },
  watch: {
    date: {
      immediate: true,
      handler: async function () {
        this.dayOff = false;
        const dateAsStr = moment(this.date).format("YYYY-MM-DD");
        const daySessions = await loadDaySessions(dateAsStr);
        if (daySessions.length === 0) {
          this.dayOff = true;
          return;
        }
        const byId = {};
        for (const s of daySessions) {
          byId[s.sessionId] = s;
        }
        this.daySessionsById = byId;
      },
    },
  },
};
</script>

<style scoped></style>
