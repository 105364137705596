<template>
  <nav class="navbar zenavbar" role="navigation" aria-label="main navigation">
    <div class="navbar-brand">
      <router-link class="navbar-item" to="/user">
        <img src="@/assets/logo.jpg" width="30" height="28" />
      </router-link>
      <span class="navbar-item is-size-7 has-text-link has-text-weight-bold ">Saison 2024/2025</span>

      <a
        role="button"
        @click="() => (this.showMobileMenu = !this.showMobileMenu)"
        class="navbar-burger burger"
        aria-label="menu"
        aria-expanded="false"
        data-target="navbarContent"
      >
        <span aria-hidden="true" />
        <span aria-hidden="true" />
        <span aria-hidden="true" />
      </a>
    </div>

    <div
      id="navbarContent"
      class="navbar-menu"
      :class="{ 'navbar-menu is-active': showMobileMenu }"
    >
      <div class="navbar-start">
        <router-link class="navbar-item" to="/user">Planning</router-link>
        <router-link class="navbar-item" to="/user/profile"
          >Mon Profil</router-link
        >
      </div>

      <div class="navbar-end">
        <div class="navbar-item">{{ name }}</div>
        <div class="navbar-item">
          <div class="buttons">
            <div>
              <span class="button is-light" @click="signOut"> Logout </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </nav>
</template>

<script>
import { fSignOut } from "@/services/firebase-auth";

export default {
  name: "UserNavbar",
  data: function () {
    return {
      showMobileMenu: false,
    };
  },
  methods: {
    signOut: function () {
      /* Indirect call to change context of execution of fSignOut
      and have this.$router available within function
      very good pointer here:
      https://dmitripavlutin.com/gentle-explanation-of-this-in-javascript/
      to understand use of "this" and all different contexts of execution

      My understanding:
      "signOut" is called using javascript "methods invocation", so the
      context within "signOut" is the object context that called it
      (vuejs instance object here).
      Thus, within "signOut", "this" is the vuejs object and
      "this.$router" the router object.
      But when fSignOut is called directly (i.e. fSignIn()),
      we are in the case of a function invocation so its context will
      be either the global window objet or "undefined" (cf 4.2.1 of
      "Secrets of the Javascript Ninja, 2nd edition). Here it is
      "undefined" probably because we are in strict mode.

      To sum up we are in the case of an object method inner function
      call (2.3 in pointer above)
      */
      fSignOut.call(this);
    },
  },
  computed: {
    name() {
      return this.$store.state.user.displayName;
    },
  },
};
</script>

<style scoped></style>
