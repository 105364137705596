<template>
  <div class="modal-card">
    <header class="modal-card-head has-background-primary">
      <p class="modal-card-title has-text-white">
        <span v-if="session.userIsEnrolled">
          Validation de ma désinscription
        </span>
        <span v-else> Validation de mon inscription </span>
      </p>
    </header>
    <section class="modal-card-body">
      <div v-if="session.userIsEnrolled">
        <p class="mb-3">
          Vous souhaitez vous désinscrire du cours de
          <strong>"{{ session.name }}"</strong> du
          <strong>{{ dateAsStr() }} à {{ session.startTime }}</strong>
          avec {{ session.teacher }}.
        </p>
        <p class="mb-3">
          Le cours dure <strong>{{ session.duration }} minutes</strong>
          qui seront recréditées sur votre Crédit Temps Prévisionnel.
        </p>
        <p class="mb-3">
          Veuillez cliquer sur le bouton <strong>"Valider"</strong>
          ci-dessous pour finaliser votre désinscription.
        </p>
        <p class="mb-3">
          Merci d'avoir pensé à annuler votre cours! Cela fera très certainement
          un.e heureux.se
        </p>
        <p>
          <o-icon icon="face-smile" size="large" variant="warning-dark">
          </o-icon>
        </p>
      </div>
      <div v-else>
        <p class="mb-3">
          Vous souhaitez vous inscrire au cours de
          <strong>"{{ session.name }}"</strong> du
          <strong>{{ dateAsStr() }} à {{ session.startTime }}</strong>
          avec {{ session.teacher }}.
        </p>

        <p v-if="!!session.info" class="mb-3">
          <span class="has-text-danger-dark">{{ session.info }}</span>
        </p>

        <p class="mb-3">
          Ce cours est de niveau
          <span class="has-text-danger-dark">{{ session.level }}</span
          >.
        </p>
        <p class="mb-3">
          Le cours dure <strong>{{ session.duration }} minutes</strong>
          qui seront déduites de votre Crédit Temps Prévisionnel.
        </p>
        <p>
          Veuillez cliquer sur le bouton <strong>"Valider"</strong>
          ci-dessous pour finaliser votre inscription.
        </p>
      </div>
    </section>
    <footer class="modal-card-foot">
      <button class="button is-success" @click="validate()">Valider</button>
    </footer>
  </div>
  <o-loading v-model:active="isLoading">
    <o-icon icon="sync" size="large" spin> </o-icon>
  </o-loading>
</template>
<script>
import moment from "moment";
import { mapState } from "vuex";
import { GET_USER } from "@/store/action-types";
import {
  enrollUserCallable,
  unEnrollUserCallable,
} from "@/services/firebase-functions";

export default {
  name: "SessionEnroll",
  props: {
    session: Object,
    date: Date,
  },
  emits: [
    "close"
  ],
  data() {
    return {
      isLoading: false,
    };
  },
  computed: {
    ...mapState([
      // map this.user to store.state.user
      "user",
    ]),
  },
  methods: {
    dateAsStr() {
      return moment(this.date).format("dddd D MMMM YYYY");
    },
    async validate() {
      let ret = {};
      let response = undefined;
      try {
        this.isLoading = true;
        if (!this.session.userIsEnrolled) {
          response = await enrollUserCallable(
            this.session.sessionId,
            this.user.email,
          );
          if (response == "Success") {
            this.$store.dispatch(GET_USER);
            ret.reason = "enrollSuccess";
          } else ret = { reason: "enrollError", error: response };
        } else {
          const response = await unEnrollUserCallable(
            this.session.sessionId,
            this.user.email,
          );
          if (response == "Success") {
            this.$store.dispatch(GET_USER);
            ret.reason = "unenrollSuccess";
          } else ret = { reason: "unenrollError", error: response };
        }
        this.isLoading = false;
      } catch (error) {
        console.log(`SessionEnroll validate error: ${error}`);
        if (!this.session.userIsEnrolled) ret = { reason: "enrollError", error: response };
        else ret = { reason: "unenrollError", error: response };
        this.isLoading = false;
      } finally {
        this.$emit("close", ret);
      }
    },
  },
};
</script>
