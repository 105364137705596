<template>
  <!-- TODO  oncreate={() => actions.session.loadDaySessions(dateAsStr)}-->
  <div key="{dateAsStr}">
    <div class="container">
      <h3 class="title tcentered">Planning</h3>
    </div>

    <div class="container">
      <div class="columns">
        <div class="column is-narrow">
          <Calendar :min="60" :max="120" />
        </div>
        <div class="column box session-list">
          <DailySessionList />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Calendar from "@/components/Calendar";
import DailySessionList from "@/components/admin/planning/DailySessionList";

export default {
  name: "Planning",
  components: { DailySessionList, Calendar },
};
</script>

<style scoped>
.session-list {
  margin: 11px;
}
</style>
