import {
  getAuth,
  createUserWithEmailAndPassword,
  sendPasswordResetEmail,
  signInWithEmailAndPassword,
  signOut,
  deleteUser,
} from "firebase/auth";
import { UPDATE_USER, CLEAR_USER } from "@/store/mutation-types";

var auth;

export const initFirebaseAuth = () => {
  auth = getAuth();
};

export const fRegister = async (email, password) => {
  try {
    const userCredential = await createUserWithEmailAndPassword(
      auth,
      email,
      password,
    );
    console.log(
      `${userCredential.user.email} successfully registered (automatically signed in)`,
    );
  } catch (error) {
    console.log(`fRegister errorMessage: ${error.message}`);
    /* propagate exception to call function */
    throw error;
  }
};

export const fResetPassword = async (email) => {
  try {
    await sendPasswordResetEmail(auth, email);
  } catch (error) {
    console.log(`fResetPassword errorMessage: ${error.message}`);
    throw error;
  }
};

export const fSignIn = async (email, password) => {
  try {
    const userCredential = await signInWithEmailAndPassword(
      auth,
      email,
      password,
    );
    console.log(`${userCredential.user.email} successfully logged in`);
  } catch (error) {
    console.log(`fSignIn errorMessage: ${error.message}`);
    throw error;
  }
};

export async function fSignOut() {
  try {
    await signOut(auth);
    console.log(`${this.$store.state.user.email} successfully signed out`);
    this.$store.commit(CLEAR_USER);
    this.$router.push("/login");
  } catch (error) {
    console.log(`Error when signing out ${this.$store.state.user.email}`);
  }
}

export const fDeleteUser = async () => {
  const user = auth.currentUser;
  try {
    await deleteUser(user);
  } catch (err) {
    console.log(err);
  }
};
