<template>
  <tr @click="$emit('click', $event)">
    <td class="">{{ user.id }}</td>
    <td class="is-hidden-mobile">{{ user.firstName }}</td>
    <td class="is-hidden-mobile">{{ user.lastName }}</td>
    <td class="">{{ remainingHoursAsStr }}</td>
  </tr>
</template>

<script>
import * as util from "@/services/util";

export default {
  name: "UserLine",
  props: {
    user: Object,
  },
  data: function () {
    return {
      remainingTime: "",
    };
  },
  methods: {},
  computed: {
    remainingHoursAsStr: function () {
      if (!this.user) {
        return "";
      }
      const tp = this.user.timePaid ? this.user.timePaid : 0;
      const tu = this.user.timeUsed ? this.user.timeUsed : 0;
      return util.minutesToPrettyString(tp - tu);
    },
  },
};
</script>

<style scoped></style>
