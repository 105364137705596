<template>
  <div>
    <div class="container">
      <h3 class="title tcentered">{{ title }}</h3>
    </div>

    <div class="columns is-centered">
      <div class="column is-8">
        <table class="table is-hoverable is-fullwidth">
          <thead>
            <th class="tcentered">Présence</th>
            <th>Email</th>
            <th>Nom</th>
          </thead>
          <tbody>
            <PresenceLine
              v-for="up in orderedPresenceList"
              :key="up.id"
              :user-id="up.userId"
              :presence="up.presence"
              :loading="sessionDataLoading"
              @change="updateUserPresence(up.userId, $event)"
            />
            <tr>
              <td></td>
              <td>
                <o-field label="Recherche d'un adhérent">
                  <o-autocomplete
                    rounded
                    clearable
                    icon="search-plus"
                    placeholder="exemple: gisele.halimi@peace.org"
                    :open-on-focus="true"
                    :keep-first="true"
                    v-model="userAdderFilter"
                    :data="filteredPotentialExtraUsers"
                    :loading="userDataLoading"
                    @select="(u) => this.updateUserPresence(u, true)"
                  >
                  </o-autocomplete>
                </o-field>
              </td>
              <td></td>
            </tr>
          </tbody>
        </table>

        <div class="tright">
          <button
            class="button is-primary"
            :disabled="saveInProgress || validated"
            @click="saveCurrentSession"
          >
            <span v-if="saveInProgress"
              ><font-awesome-icon icon="spinner" pulse
            /></span>
            <span v-else>Valider</span>
          </button>
        </div>
      </div>
    </div>

    <div class="notification has-text-justified is-danger is-light">
      <p>
        La validation d'un cours est <strong>définitive</strong>. La validation
        doit être faite après le début du cours, idéalement à la fin. Les
        crédits temps des adhérents sont mis à jour définitivement suite à la
        validation.
      </p>
    </div>

    <o-notification
      v-if="notifSaveSessionSuccess"
      @close="notifSaveSessionSuccess = false"
      variant="primary"
      v-bind:duration="6000"
      auto-close
    >
      L'enregistrement du cours a bien été réalisé !
    </o-notification>
    <o-notification
      v-if="notifSaveSessionError"
      @close="notifSaveSessionError = false"
      variant="danger"
      v-bind:duration="6000"
      auto-close
    >
      Une erreur s'est produite lors de l'enregistrement du cours !
    </o-notification>
  </div>
</template>

<script>
import moment from "moment";
import PresenceLine from "@/components/admin/session/PresenceLine";
import { sessionsById } from "@/services/sessions-agenda";
import { GET_All_USERS } from "@/store/action-types";
import { loadSession, saveSession } from "@/services/firestore";

export default {
  name: "SessionDetails",
  components: { PresenceLine },
  props: {
    sessionId: String,
    sessionDateStr: String,
  },
  data() {
    return {
      orderedPresenceList: [],
      saveInProgress: false,

      sessionData: undefined,
      sessionDataLoading: true,
      validated: false,

      userDataLoading: true,
      allUsers: undefined,

      userAdderFilter: undefined,
      userAdderSelected: undefined,

      notifSaveSessionSuccess: false,
      notifSaveSessionError: false,
    };
  },
  computed: {
    date: function () {
      moment.locale("fr");
      return moment(this.sessionDateStr, "MM-DD");
    },
    title: function () {
      let session = sessionsById[this.sessionId];
      return (
        session.startTime +
        " - " +
        session.name +
        " - " +
        moment(this.date).format("dddd D MMMM")
      );
    },
    potentialExtraUsers: function () {
      if (!this.allUsers) {
        this.loadAllUsers();
        return [];
      }

      let result = this.allUsers.map((u) => u.id.toLowerCase());
      return result;
    },
    filteredPotentialExtraUsers: function () {
      if (!this.userAdderFilter) {
        return this.potentialExtraUsers;
      } else {
        let filter = this.userAdderFilter.toLowerCase();
        return this.potentialExtraUsers.filter((u) => u.indexOf(filter) >= 0);
      }
    },
  },
  created: async function () {
    await this.loadCurrentSession();
  },
  methods: {
    updateUserPresence(userId, newPresence) {
      if (!userId) {
        return;
      }

      let userPresence = this.orderedPresenceList.find(
        (up) => up.userId === userId,
      );
      if (!userPresence) {
        this.orderedPresenceList.push({
          userId: userId,
          presence: newPresence,
        });
      } else {
        userPresence.presence = newPresence;
      }
    },
    async loadAllUsers() {
      try {
        this.allUsers = await this.$store.dispatch(GET_All_USERS);
      } finally {
        this.userDataLoading = false;
      }
    },
    async loadCurrentSession() {
      try {
        this.sessionDataLoading = true;
        this.sessionData = await loadSession(
          this.sessionId,
          this.sessionDateStr,
        );

        if (this.sessionData.adminUpdatedAt !== undefined)
          this.validated = true;

        const sortedUsers = [...this.sessionData.users].sort();
        for (const u of sortedUsers) {
          this.orderedPresenceList.push({
            userId: u,
            presence: true,
          });
        }
      } catch (error) {
        console.log(`loadSession error: ${error}`);
      } finally {
        this.sessionDataLoading = false;
      }
    },
    async saveCurrentSession() {
      this.saveInProgress = true;

      try {
        await saveSession(
          this.sessionDateStr,
          this.sessionData,
          this.orderedPresenceList
            .filter((v) => v.presence)
            .reduce((acc, v) => {
              acc[v.userId] = v.presence;
              return acc;
            }, {}),
        );
        this.notifSaveSessionSuccess = true;
        /* reset presents list and reloads from collection */
        this.orderedPresenceList = [];
        await this.loadCurrentSession();
      } catch (error) {
        console.log(`saveSession error: ${error}`);
        this.notifSaveSessionError = true;
      } finally {
        this.saveInProgress = false;
      }
    },
  },
};
</script>
