import { createStore, createLogger } from "vuex";
import {
  UPDATE_USER,
  CLEAR_USER,
  SET_DATE,
  CACHE_All_USERS,
  CLEAR_USERS_CACHE,
  CACHE_STATS,
} from "./mutation-types";
import { GET_All_USERS, GET_USER, GET_STATS } from "./action-types";
import { loadAllUsers, getUser, loadStats } from "@/services/firestore";

const debug = process.env.NODE_ENV !== "production";

export default createStore({
  strict: debug,
  plugins: debug ? [createLogger()] : [],
  state: {
    date: new Date(),
    user: undefined,
    users: undefined,
    stats: undefined,
  },
  getters: {
    isUserAuthenticated(state) {
      return state.user !== undefined && !state.user.isAdmin;
    },
    isAdminAuthenticated(state) {
      return state.user !== undefined && state.user.isAdmin;
    },
  },
  mutations: {
    [UPDATE_USER](state, payload) {
      state.user = {
        displayName: payload.displayName,
        email: payload.email,
        isAdmin: payload.isAdmin,
        timePaid: payload.timePaid,
        timeUsed: payload.timeUsed,
        forecastTimeUsed: payload.forecastTimeUsed,
      };
    },
    [CLEAR_USER](state) {
      state.user = undefined;
    },
    [SET_DATE](state, payload) {
      state.date = payload;
    },
    [CACHE_All_USERS](state, payload) {
      state.users = payload;
    },
    [CACHE_STATS](state, payload) {
      state.stats = payload;
    },
    [CLEAR_USERS_CACHE](state) {
      state.users = undefined;
    },
  },
  actions: {
    async [GET_All_USERS](context) {
      if (context.state.users) {
        return context.state.users;
      }

      const result = await loadAllUsers();
      context.commit(CACHE_All_USERS, result);
      return result;
    },
    async [GET_USER](context) {
      if (context.state.user === undefined) {
        console.log("User must be already in cache to update it");
        return;
      }
      const userData = await getUser(context.state.user.email);
      if (userData !== undefined) {
        context.commit(UPDATE_USER, {
          displayName: `${userData.firstName} ${userData.lastName}`,
          email: context.state.user.email,
          timePaid: userData.timePaid,
          timeUsed: userData.timeUsed,
          forecastTimeUsed: userData.forecastTimeUsed,
          isAdmin: false,
        });
      }
      return userData;
    },
    async [GET_STATS](context) {
      if (context.state.stats) return context.state.stats;

      const result = await loadStats();
      context.commit(CACHE_STATS, result);
      return result;
    },
  },
});
