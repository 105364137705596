/* direct objects import from yaml conf thanks to vue-cli-plugin-yaml
https://github.com/edus44/vue-cli-plugin-yaml */
import { collections, season } from "../../config/season.yaml";

export const USERS_COLLECTION = `${collections.users}${season}`;
export const TEACHERS_COLLECTION = `${collections.teachers}${season}`;
export const SESSIONS_COLLECTION = `${collections.sessions}${season}`;
export const RESERVED_PLACES_COLLECTION = `${collections.reservedPlaces}${season}`;
export const STATS_COLLECTION = `${collections.stats}`;
export const STATS_DOCUMENT = `${season}`;
