import { createRouter, createWebHistory } from "vue-router";
import store from "@/store";
import Login from "@/components/Login";
import Register from "@/components/Register";
import Planning from "@/components/admin/planning/Planning";
import Session from "@/components/admin/session/SessionDetails";
import Students from "@/components/admin/users/Students";
import Stats from "@/components/admin/Stats";
import UserPlanning from "@/components/user/Planning";
import UserProfile from "@/components/user/Profile";

const routes = [
  {
    path: "/",
    redirect: (to) => {
      return "/login";
    },
  },
  {
    path: "/login",
    name: "Login",
    component: Login,
    meta: { requiresAuth: false },
  },
  {
    path: "/register",
    name: "Register",
    component: Register,
    meta: { requiresAuth: false },
  },
  {
    path: "/admin",
    name: "Planning",
    component: Planning,
    meta: {
      requiresAuth: true,
      requiresAdminAuth: true,
      requiresUserAuth: false,
    },
  },
  {
    path: "/admin/session/:sessionId/:sessionDateStr",
    name: "Session",
    component: Session,
    props: true,
    meta: {
      requiresAuth: true,
      requiresAdminAuth: true,
      requiresUserAuth: false,
    },
  },
  {
    path: "/admin/users",
    component: Students,
    name: "Users",
    meta: {
      requiresAuth: true,
      requiresAdminAuth: true,
      requiresUserAuth: false,
    },
  },
  {
    path: "/admin/stats",
    component: Stats,
    name: "Stats",
    meta: {
      requiresAuth: true,
      requiresAdminAuth: true,
      requiresUserAuth: false,
    },
  },
  {
    path: "/user",
    name: "UserPlanning",
    component: UserPlanning,
    meta: {
      requiresAuth: true,
      requiresAdminAuth: false,
      requiresUserAuth: true,
    },
  },
  {
    path: "/user/profile",
    name: "UserProfile",
    component: UserProfile,
    meta: {
      requiresAuth: true,
      requiresAdminAuth: false,
      requiresUserAuth: true,
    },
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

router.beforeEach((to, from) => {
  if (!to.meta.requiresAuth) return;
  else {
    if (to.meta.requiresUserAuth && !store.getters.isUserAuthenticated) {
      console.log("route needs user authentication, redirecting to /login");
      return "/login";
    }
    if (to.meta.requiresAdminAuth && !store.getters.isAdminAuthenticated) {
      console.log("route needs admin authentication, redirecting to /login");
      return "/login";
    }
  }
});

export default router;
