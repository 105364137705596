import {
  getFirestore,
  collection,
  query,
  where,
  getDocs,
  doc,
  getDoc,
  setDoc,
  updateDoc,
  deleteDoc,
  increment,
  serverTimestamp,
} from "firebase/firestore";
import * as notif from "@/services/notifications";
import { findStartDate } from "./util";
import {
  USERS_COLLECTION,
  TEACHERS_COLLECTION,
  SESSIONS_COLLECTION,
  STATS_COLLECTION,
  STATS_DOCUMENT,
} from "@/config/firestore";

var db;
var sessions;
var users;
var stats;

export const initFirestore = () => {
  db = getFirestore();
  sessions = collection(db, SESSIONS_COLLECTION);
  users = collection(db, USERS_COLLECTION);
  stats = collection(db, STATS_COLLECTION);
};

export const loadSession = async (sessionId, dateAsStr) => {
  try {
    const id = `${sessionId}-${dateAsStr}`;
    const docRef = doc(sessions, id);
    const docSnap = await getDoc(docRef);
    const session = docSnap.exists() ? docSnap.data() : undefined;
    return session;
  } catch (err) {
    console.log(err);
    notif.error(err);
  }
};

export const loadDaySessions = async (dateAsStr) => {
  try {
    const q = query(sessions, where("date", "==", dateAsStr));
    const querySnapshot = await getDocs(q);
    const daySessions = [];
    querySnapshot.forEach((doc) => {
      daySessions.push(doc.data());
    });
    return daySessions;
  } catch (err) {
    notif.error(err);
    console.log(err);
    throw err;
  }
};

/*
export const loadAllUserSessions = async (userId, startYear) => {
  const endYear = startYear + 1;
  const start = findStartDate(startYear);
  const end = findStartDate(endYear);

  try {
    const q = query(sessions, where('date', '>=', start),
      where('date', '<', end), where('users', 'array-contains', userId));
    const querySnapshot = await getDoc(q);
    const userSessions = [];
    querySnapshot.forEach((doc) => {
      userSessions.push(doc.data());
    });
    return userSessions;
  } catch (err) {
    notif.error(err);
    console.log(err);
  }
};
*/

export const loadAllSessionsForYear = async (startYear) => {
  const endYear = startYear + 1;
  const start = startYear + "-08-01";
  const end = endYear + "-08-01";

  try {
    const querySnapshot = await sessions
      .where("date", ">=", start)
      .where("date", "<", end)
      .get();

    let consumedKarmabox = 0;
    const consumedByUserId = {};
    querySnapshot.forEach((doc) => {
      const session = doc.data();
      // debugger;
      for (let i in session.users) {
        const u = session.users[i];
        if (session.karmabox && session.karmabox.includes(u)) {
          consumedKarmabox += session.durationInMin;
        } else if (session.trials && session.trials.includes(u)) {
          // no op
        } else if (session.oneshots && session.oneshots.includes(u)) {
          // no op
        } else {
          consumedByUserId[u] =
            (consumedByUserId[u] || 0) + session.durationInMin;
        }
      }
    });
    return { consumedKarmabox, consumedByUserId };
  } catch (err) {
    notif.error(err);
    console.log(err);
  }
};

export const saveSession = async (dateAsStr, sessionData, presences) => {
  try {
    const id = `${sessionData.sessionId}-${dateAsStr}`;
    const docRef = doc(sessions, id);
    const users = Object.keys(presences);
    const presents = [];
    for (const i in users) {
      const u = users[i];
      if (presences[u]) {
        presents.push(u);
      }
    }

    await updateDoc(docRef, {
      users: presents,
      adminUpdatedAt: serverTimestamp(),
    });

    await updateUsersTimeUsed(presences, sessionData);
  } catch (err) {
    console.log(err);
    throw err;
  }
};

export const getUser = async (email) => {
  try {
    const docRef = doc(db, USERS_COLLECTION, email);
    const docSnap = await getDoc(docRef);
    if (docSnap.exists()) return docSnap.data();
    else return undefined;
  } catch (err) {
    console.log(err);
    return undefined;
  }
};

export const getTeacher = async (email) => {
  try {
    const docRef = doc(db, TEACHERS_COLLECTION, email);
    const docSnap = await getDoc(docRef);
    if (docSnap.exists()) return docSnap.data();
    else return undefined;
  } catch (err) {
    console.log(err);
    return undefined;
  }
};

export const loadAllUsers = async () => {
  try {
    const querySnapshot = await getDocs(users);
    const result = [];
    querySnapshot.forEach((doc) => {
      const data = doc.data();
      data.id = doc.id;
      result.push(data);
    });
    return result;
  } catch (err) {
    notif.error(err);
    console.log(err);
  }
};

export const saveUser = async (user, failIfExists) => {
  if (!user.timePaid && user.timePaid !== 0) {
    throw new Error(
      "timePaid field is required (timePaid=" + user.timePaid + ")",
    );
  }
  if (!user.sessions) {
    throw new Error("sessions field is required");
  }
  if (failIfExists) {
    const docRef = doc(users, user.id);
    const existingUser = await getDoc(docRef);
    if (existingUser.exists()) {
      throw Error("user '" + user.id + "' already exists");
    }
  }
  try {
    let data = {
      timePaid: user.timePaid,
      sessions: user.sessions,
    };
    if (user.firstName) {
      data.firstName = user.firstName;
    }
    if (user.lastName) {
      data.lastName = user.lastName;
    }

    const docRef = doc(users, user.id);
    await docRef.setDoc(data, { merge: true });
  } catch (err) {
    notif.error(err);
    console.log(err);
  }
};

export const deleteUser = async (userId) => {
  try {
    const docRef = doc(users, userId);
    await deleteDoc(userId);
  } catch (err) {
    notif.error(err);
    console.log(err);
  }
};

export const updateUsersTimeUsed = async (presences, sessionData) => {
  const enrolledUsers = Object.keys(presences);
  console.log(`EnrolledUsers: ${enrolledUsers}`);
  console.log(`sessionData enrolledUsers: ${sessionData.users}`);
  for (const i in enrolledUsers) {
    const u = enrolledUsers[i];
    console.log(`EnrolledUser: ${u}`);
    await incUserTimeUsed(u, sessionData.durationInMin);
    if (sessionData.users === undefined || sessionData.users.indexOf(u) < 0) {
      /* if the user was added by the teacher, its forecastTimeUsed
         must be incremented too */
      console.log(
        `${u} was added by the professor, increase its forecastTimeUsed too`,
      );
      await incUserForecastTimeUsed(u, sessionData.durationInMin);
    }
  }
  for (const i in sessionData.users) {
    const u = sessionData.users[i];
    if (enrolledUsers.indexOf(u) < 0)
      /* decrement forecastTimeUsed of users who were
        enrolled in the session but didn't show up, so have been
        removed from the presence list by session teacher */
      await decUserForecastTimeUsed(u, sessionData.durationInMin);
  }
};

export const incUserTimeUsed = async (userId, durationInMin) => {
  try {
    const docRef = doc(db, USERS_COLLECTION, userId);
    await updateDoc(docRef, {
      timeUsed: increment(durationInMin),
    });
  } catch (err) {
    console.log(`incUserTimeUsed error: ${err}`);
    notif.error(err);
    throw err;
  }
};

export const incUserForecastTimeUsed = async (userId, durationInMin) => {
  try {
    const docRef = doc(db, USERS_COLLECTION, userId);
    await updateDoc(docRef, {
      forecastTimeUsed: increment(durationInMin),
    });
  } catch (err) {
    console.log(`decUserForecastTimeUsed error: ${err}`);
    notif.error(err);
    throw err;
  }
};

export const decUserForecastTimeUsed = async (userId, durationInMin) => {
  try {
    const docRef = doc(db, USERS_COLLECTION, userId);
    await updateDoc(docRef, {
      forecastTimeUsed: increment(-durationInMin),
    });
  } catch (err) {
    console.log(`decUserForecastTimeUsed error: ${err}`);
    notif.error(err);
    throw err;
  }
};

export const saveUserConso = async (userId, consoInMin) => {
  if (!userId) {
    throw new Error("userId is required");
  }
  if (consoInMin === null || consoInMin === undefined) {
    throw new Error("consoInMin is required");
  }
  try {
    let data = {
      timeUsed: consoInMin,
    };
    await users.doc(userId).set(data, { merge: true });
  } catch (err) {
    notif.error(err);
    console.log(err);
  }
};

export const loadStats = async () => {
  try {
    const docRef = doc(db, STATS_COLLECTION, STATS_DOCUMENT);
    const docSnap = await getDoc(docRef);
    if (docSnap.exists()) return docSnap.data();
    else return undefined;
  } catch (err) {
    console.log(err);
    return undefined;
  }
};
