<template>
  <div class="main-calendar">
    <o-field label="Sélectionnez une date">
      <o-datepicker
        v-model="selectedDate"
        icon="calendar"
        locale="fr-FR"
        :first-day-of-week="1"
        :unselectable-days-of-week="[0, 6]"
        :min-date="minDate"
        :max-date="maxDate"
      >
      </o-datepicker>
    </o-field>
  </div>
</template>

<script>
import { SET_DATE } from "@/store/mutation-types";

export default {
  name: "Calendar",
  props: {
    min: {
      type: Number,
      default: 0,
    },
    max: {
      type: Number,
      default: 30,
    },
  },
  data: function () {
    const today = new Date();

    return {
      minDate: new Date(
        today.getFullYear(),
        today.getMonth(),
        today.getDate() - this.min,
      ),
      maxDate: new Date(
        today.getFullYear(),
        today.getMonth(),
        today.getDate() + this.max,
      ),
      selectedDate: this.$store.state.date,
    };
  },
  watch: {
    selectedDate: {
      handler: function (newSelectedDate) {
        this.$store.commit(SET_DATE, newSelectedDate);
      },
      immediate: true,
    },
  },
};
</script>

<style scoped></style>
