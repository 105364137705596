import { getFunctions, httpsCallable } from "firebase/functions";

var functions;

export const initFirebaseFunctions = (app) => {
  functions = getFunctions(app, "europe-west1");
};

export const enrollUserCallable = async (sessionId, userEmail) => {
  const enrollUser = httpsCallable(functions, "enrollUser");
  try {
    const result = await enrollUser({
      sessionid: sessionId,
      useremail: userEmail,
    });
    const data = result.data;
    console.log("enrollUser firebase function successfull");
    console.log(`returned data: ${data}`);
    return data;
  } catch (error) {
    // Getting the Error details.
    const code = error.code;
    const message = error.message;
    const details = error.details;
    console.log("enrollUser firebase function failed");
    throw error;
  }
};

export const unEnrollUserCallable = async (sessionId, userEmail) => {
  const unenrollUser = httpsCallable(functions, "unenrollUser");
  try {
    const result = await unenrollUser({
      sessionid: sessionId,
      useremail: userEmail,
    });
    const data = result.data;
    console.log("unenrollUser firebase function successfull");
    console.log(`returned data: ${data}`);
    return data;
  } catch (error) {
    // Getting the Error details.
    const code = error.code;
    const message = error.message;
    const details = error.details;
    console.log("unenrollUser firebase function failed");
    throw error;
  }
};
