<template>
  <section class="section">
    <div class="container" v-if="!loading">
      <div class="box">
        <table class="table is-striped is-hoverable is-fullwidth">
          <tbody>
            <tr>
              <th class="has-text-success">Nombre d'adhérents</th>
              <td>
                <strong class="has-text-link">{{ stats.users }}</strong>
              </td>
            </tr>
            <tr>
              <th class="has-text-success">
                Nombre d'adhérents avec crédit temps
              </th>
              <td>
                <strong>{{ stats.users_timepaid }}</strong>
              </td>
            </tr>
            <tr>
              <th class="has-text-success">
                Nombre d'adhérents avec réservation
              </th>
              <td>
                <strong>{{ stats.users_reservation }}</strong>
              </td>
            </tr>
            <tr>
              <th class="has-text-success">Nombre d'adhérents suspendus</th>
              <td>
                <strong class="has-text-link">{{ stats.users_karma }}</strong>
              </td>
            </tr>
            <tr>
              <th class="has-text-success">Total crédits temps</th>
              <td>
                <strong>{{ credits }}</strong>
              </td>
            </tr>
            <tr>
              <th class="has-text-success">Total crédits suspendus délivrés</th>
              <td>
                <strong>{{ karma }}</strong>
              </td>
            </tr>
            <tr>
              <th class="has-text-success">
                Total crédits suspendus consommés
              </th>
              <td>
                <strong class="has-text-link">{{ karma_consumed }}</strong>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </section>
  <section class="section">
    <div class="container" v-if="!loading">
      <div class="box">
        <h1 class="title">Fréquentation</h1>
        <table class="table is-striped is-hoverable is-fullwidth">
          <tbody>
            <tr>
              <th class="has-text-success">Moyenne totale</th>
              <td>
                <strong class="has-text-link">{{
                  stats.sessions.average
                }}</strong>
              </td>
            </tr>
            <tr>
              <th class="has-text-success">Lundi (Mysore Héloise)</th>
              <td>
                <strong>{{ stats.sessions.lun1.average }}</strong>
              </td>
            </tr>
            <tr>
              <th class="has-text-success">Lundi (Yin Héloise)</th>
              <td>
                <strong>{{ stats.sessions.lun2.average }}</strong>
              </td>
            </tr>
            <tr>
              <th class="has-text-success">Lundi (Ashtanga guidé Olivier)</th>
              <td>
                <strong>{{ stats.sessions.lun3.average }}</strong>
              </td>
            </tr>
            <tr>
              <th class="has-text-success">Lundi (Vinyasa Anouk)</th>
              <td>
                <strong>{{ stats.sessions.lun4.average }}</strong>
              </td>
            </tr>
            <tr>
              <th class="has-text-success">Mardi (Vinyasa PAM Héloise)</th>
              <td>
                <strong>{{ stats.sessions.mar1.average }}</strong>
              </td>
            </tr>
            <tr>
              <th class="has-text-success">Mercredi (Ashtanga Héloise)</th>
              <td>
                <strong>{{ stats.sessions.mer1.average }}</strong>
              </td>
            </tr>
            <tr>
              <th class="has-text-success">Mercredi (Hatha Raggi)</th>
              <td>
                <strong>{{ stats.sessions.mer2.average }}</strong>
              </td>
            </tr>
            <tr>
              <th class="has-text-success">
                Mercredi (Vinyasa Quadri Héloise)
              </th>
              <td>
                <strong>{{ stats.sessions.mer3.average }}</strong>
              </td>
            </tr>
            <tr>
              <th class="has-text-success">Mercredi (Hatha Manon)</th>
              <td>
                <strong>{{ stats.sessions.mer4.average }}</strong>
              </td>
            </tr>
            <tr>
              <th class="has-text-success">Jeudi (Ashtanga Manon)</th>
              <td>
                <strong>{{ stats.sessions.jeu1.average }}</strong>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </section>
</template>
<script>
import { GET_STATS } from "@/store/action-types";

export default {
  name: "Stats",
  data() {
    return {
      loading: true,
      stats: undefined,
      credits: 0,
      karma: 0,
      karma_consumed: 0,
    };
  },
  methods: {
    creditsInHours(credits) {
      let positive = true;
      if (credits == 0) return "0";
      if (credits < 0) {
        positive = false;
        credits = -credits;
      }
      const hours = Math.floor(credits / 60);
      const minutes = credits % 60;
      if (minutes > 0) {
        if (positive) return `${hours.toString()}h${minutes.toString()}`;
        else return `-${hours.toString()}h${minutes.toString()}`;
      } else {
        if (positive) return `${hours.toString()}h`;
        else return `-${hours.toString()}h`;
      }
    },
  },
  created: async function () {
    this.loading = true;
    try {
      this.stats = await this.$store.dispatch(GET_STATS);
    } catch (error) {
      console.log(`stats loading error: ${error}`);
    } finally {
      this.loading = false;
    }
    this.credits = this.creditsInHours(this.stats.credits);
    this.karma = this.creditsInHours(this.stats.karma);
    this.karma_consumed = this.creditsInHours(this.stats.karma_consumed);
  },
};
</script>
