<template>
  <div class="columns is-centered">
    <div class="column is-half box mt-5">
      <p class="has-text-centered">
        <strong class="has-text-primary is-size-3"
          >Le chien tête en bas Rennes</strong
        >
      </p>
      <p class="has-text-centered has-text-primary is-size-4">Connexion</p>
      <div class="m-3">
        <o-field label="Email">
          <o-input
            type="email"
            v-model="email"
            placeholder="gisele.halimi@exemple.fr"
            maxlength="128"
          >
          </o-input>
        </o-field>
        <o-field label="Mot de passe">
          <o-input
            type="password"
            v-model="password"
            placeholder="mongrandsecret"
            password-reveal
          >
          </o-input>
        </o-field>
        <o-loading v-model:active="isLoading">
          <o-icon icon="sync" size="large" spin> </o-icon>
        </o-loading>
        <button class="button is-primary" @click="login">Se connecter</button>
      </div>
      <div class="ml-3 mr-3 mt-5 field">
        <label class="label">
          <strong class="has-text-primary">Première utilisation?</strong>
        </label>
        <div class="control">
          <router-link class="button is-primary is-outlined" to="/register"
            >Je m'enregistre</router-link
          >
        </div>
      </div>
      <div class="mr-3 has-text-right has-text-primary">
        Mot de passe oublié?
        <span
          class="is-underlined my-click-to-reset"
          @click="isResetModalActive = true"
          >Cliquez ici</span
        >
      </div>
    </div>
  </div>

  <o-modal
    v-model:active="isResetModalActive"
    has-modal-card
    trap-focus
    :destroy-on-hide="true"
    aria-role="dialog"
    aria-modal
  >
    <reset-password
      @close="isResetModalActive = false"
      @success="resetSuccess"
      @error="resetError"
    >
    </reset-password>
  </o-modal>

  <div class="columns is-centered">
    <div class="column is-half">
      <o-notification
        v-if="passwordJustReset"
        @close="passwordJustReset = false"
        variant="primary"
        closable
        aria-close-label="Close notification"
      >
        Votre mot de passe a été réinitialisé. Un email vous a été envoyé pour
        recréer un mot de passe.
      </o-notification>
      <o-notification
        v-if="passwordWasNotReset"
        @close="passwordWasNotReset = false"
        variant="danger"
        closable
        aria-close-label="Close notification"
      >
        Une erreur s'est produite lors de la procédure de réinitialisation de
        votre mot de passe. Veuillez réessayer plus tard.
      </o-notification>
      <o-notification
        v-if="loginFailed"
        @close="loginFailed = false"
        variant="danger"
        closable
        aria-close-label="Close notification"
      >
        Echec lors de la tentative de connexion. Etes-vous sûr.e d'utiliser le
        bon email et/ou le bon mot de passe ? Avez-vous bien fait la procédure
        d'enregistrement (bouton "Je m'enregistre" ci-dessus) ?
      </o-notification>
    </div>
  </div>
</template>

<script>
import { fSignIn } from "@/services/firebase-auth";
import { loginRedirect } from "@/services/login";
import ResetPassword from "@/components/ResetPassword";

export default {
  name: "Login",
  components: { ResetPassword },
  data() {
    return {
      email: "",
      password: "",
      loginFailed: false,
      isLoading: false,
      passwordJustReset: false,
      passwordWasNotReset: false,
      isResetModalActive: false,
    };
  },
  methods: {
    async login() {
      try {
        this.isLoading = true;
        await fSignIn(this.email, this.password);
        await loginRedirect.call(this, this.email);
        this.isLoading = false;
      } catch (err) {
        console.log(`error in login(): ${err}`);
        this.loginFailed = true;
        this.isLoading = false;
      }
    },
    resetSuccess() {
      this.isResetModalActive = false;
      this.passwordJustReset = true;
    },
    resetError() {
      this.isResetModalActive = false;
      this.passwordWasNotReset = true;
    },
  },
};
</script>
<style scoped>
.my-click-to-reset {
  cursor: pointer;
}
</style>
