<template>
  <nav class="navbar zenavbar" role="navigation" aria-label="main navigation">
    <div class="navbar-brand">
      <router-link class="navbar-item" to="/admin">
        <img src="@/assets/logo.jpg" width="30" height="28" />
      </router-link>

      <a
        role="button"
        @click="() => (this.showMobileMenu = !this.showMobileMenu)"
        class="navbar-burger burger"
        aria-label="menu"
        aria-expanded="false"
        data-target="navbarContent"
      >
        <span aria-hidden="true" />
        <span aria-hidden="true" />
        <span aria-hidden="true" />
      </a>
    </div>

    <div
      id="navbarContent"
      class="navbar-menu"
      :class="{ 'navbar-menu is-active': showMobileMenu }"
    >
      <div class="navbar-start">
        <router-link class="navbar-item" to="/admin">Planning</router-link>
        <router-link class="navbar-item" to="/admin/users">Elèves</router-link>
        <router-link class="navbar-item" to="/admin/stats">Stats</router-link>
      </div>

      <div class="navbar-end">
        <div class="navbar-item">{{ email }}</div>
        <div class="navbar-item">
          <div class="buttons">
            <div>
              <span class="button is-light" @click="signOut"> Logout </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </nav>
</template>

<script>
import { fSignOut } from "@/services/firebase-auth";

export default {
  name: "Navbar",
  data: function () {
    return {
      showMobileMenu: false,
    };
  },
  methods: {
    signOut: function () {
      fSignOut.call(this);
    },
  },
  computed: {
    email() {
      return this.$store.state.user.email;
    },
  },
};
</script>

<style scoped></style>
