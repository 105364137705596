import "./styles/style.scss";
import { createApp } from "vue";
import { initFirebaseApp } from "@/services/firebase";
import { initFirebaseAuth } from "@/services/firebase-auth";
import { initFirestore } from "@/services/firestore";
import { initFirebaseFunctions } from "@/services/firebase-functions";
import { initSessionsAgenda } from "@/services/sessions-agenda";
import Oruga from "@oruga-ui/oruga-next";
import { bulmaConfig } from "@oruga-ui/theme-bulma";
import "@oruga-ui/theme-bulma/dist/bulma.css";
import FontAwesomeIcon from "@/config/font-awesome";
import moment from "moment";

import App from "./App.vue";
import router from "./router";
import store from "./store";

const firebaseApp = initFirebaseApp();
initFirebaseAuth();
initFirestore();
initFirebaseFunctions(firebaseApp);

initSessionsAgenda();

moment.locale("fr");

const app = createApp(App);

app.component("font-awesome-icon", FontAwesomeIcon);

const customBulmaConfig = {
  ...bulmaConfig,
  iconComponent: "font-awesome-icon",
  iconPack: "fas",
};

app.use(Oruga, customBulmaConfig);

app.use(router);

app.use(store);

app.mount("#app");
