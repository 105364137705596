<template>
  <section class="hero is-primary">
    <div class="hero-body">
      <p class="title">Mon profil adhérent</p>
    </div>
  </section>
  <section class="section">
    <div class="container">
      <div class="box">
        <table class="table is-hoverable is-fullwidth">
          <tbody>
            <tr>
              <th class="has-text-primary">Nom</th>
              <td>{{ user.displayName }}</td>
            </tr>
            <tr>
              <th class="has-text-primary">Email</th>
              <td>{{ user.email }}</td>
            </tr>
            <tr>
              <th class="has-text-primary">Crédit Initial</th>
              <td>
                <strong>{{ origCreditsInHours }}</strong>
                <a
                  class="ml-4 button is-small is-responsive is-link is-rounded"
                  href="https://www.helloasso.com/associations/le-chien-tete-en-bas-rennes/adhesions/achat-de-credit-temps"
                  ><strong>Acheter du crédit</strong></a
                >
              </td>
            </tr>
            <tr>
              <th class="has-text-primary">Crédit Temps</th>
              <td>
                <span v-if="remainCredits > 120">
                  <strong class="has-text-success-dark">
                    {{ remainCreditsInHours }}
                  </strong>
                </span>
                <span v-else>
                  <strong class="has-text-danger-dark">
                    {{ remainCreditsInHours }}
                  </strong>
                </span>
              </td>
            </tr>
            <tr>
              <th class="has-text-primary">Crédit Temps Prévisionnel</th>
              <td>
                <span v-if="forecastRemainCredits > 120">
                  <strong class="has-text-success-dark">
                    {{ forecastRemainCreditsInHours }}
                  </strong>
                </span>
                <span v-else>
                  <strong class="has-text-danger-dark">
                    {{ forecastRemainCreditsInHours }}
                  </strong>
                </span>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div class="notification has-text-justified is-primary is-light">
        <p>
          <strong>Crédit Temps</strong> : crédit temps à l'instant présent,
          seuls les cours effectivement passés ont été déduits du crédit
          initial.
        </p>
        <p class="mt-3">
          <strong>Crédit Temps Prévisionnel</strong> : le crédit temps
          prévisionnel comptabilise également les inscriptions à des cours non
          encore réalisés. Vous ne pourrez vous inscrire à un cours si votre
          crédit temps prévisionnel n'est pas suffisant. Vous regagnez du crédit
          temps prévisionnel en vous désinscrivant d'un cours.
        </p>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: "UserProfile",
  data() {
    return {
      user: undefined,
      remainCredits: 0,
      forecastRemainCredits: 0,
      origCreditsInHours: "",
      remainCreditsInHours: "",
      forecastRemainCreditsInHours: "",
    };
  },
  methods: {
    creditsInHours(credits) {
      let positive = true;
      if (credits == 0) return "0";
      if (credits < 0) {
        positive = false;
        credits = -credits;
      }
      const hours = Math.floor(credits / 60);
      const minutes = credits % 60;
      if (minutes > 0) {
        if (positive) return `${hours.toString()}h${minutes.toString()}`;
        else return `-${hours.toString()}h${minutes.toString()}`;
      } else {
        if (positive) return `${hours.toString()}h`;
        else return `-${hours.toString()}h`;
      }
    },
  },
  created() {
    this.user = this.$store.state.user;
    this.remainCredits = this.user.timePaid - this.user.timeUsed;
    this.forecastRemainCredits =
      this.user.timePaid - this.user.forecastTimeUsed;
    this.origCreditsInHours = this.creditsInHours(this.user.timePaid);
    this.remainCreditsInHours = this.creditsInHours(this.remainCredits);
    this.forecastRemainCreditsInHours = this.creditsInHours(
      this.forecastRemainCredits,
    );
  },
};
</script>
