<template>
  <span v-if="dayOff">
    <strong class="has-text-danger-dark mt-5"> Pas de cours </strong>
  </span>
  <table v-else class="table is-hoverable is-fullwidth">
    <thead>
      <th>{{ dateAsStr }}</th>
      <th>Cours</th>
      <th class="is-hidden-mobile">Professeur.e</th>
      <th class="is-hidden-mobile">Places restantes</th>
      <th />
      <th />
    </thead>
    <tbody>
      <tr v-for="s in dailySessions" :key="s.id">
        <td>
          <strong class="has-text-primary">{{ s.startTime }}</strong>
        </td>
        <td>{{ s.name }}</td>
        <td class="is-hidden-mobile">{{ s.teacher }}</td>
        <td class="is-hidden-mobile">{{ s.availability }}</td>
        <td>
          <span v-if="!s.isOver">
            <button
              v-if="!s.userIsEnrolled && s.availability > 0"
              class="button is-primary my-button"
              @click="sessionModal(s)"
            >
              Je m'inscris
            </button>
            <button
              v-else-if="s.userIsEnrolled"
              class="button is-danger my-button"
              @click="sessionModal(s)"
            >
              J'annule
            </button>
            <button
              v-if="!s.userIsEnrolled && s.availability === 0"
              class="button is-danger is-outlined my-button"
              disabled
            >
              Complet
            </button>
          </span>
          <span v-else>
            <button class="button my-button" disabled>Terminé</button>
          </span>
        </td>
      </tr>
    </tbody>
  </table>

  <o-loading v-model:active="isLoading">
    <o-icon icon="sync" size="large" spin> </o-icon>
  </o-loading>

</template>

<script>
import { mapState } from "vuex";
import moment from "moment";
import { sessions } from "../../../config/season";
import { loadDaySessions } from "@/services/firestore";
import { dateFromSessionStartTime, getDayNameFromDate } from "@/services/util";
import SessionEnroll from "@/components/user/SessionEnroll";

export default {
  name: "UserDailySessionList",
  data() {
    return {
      daySessionsById: {},
      dayOff: false,
      user: undefined,
      isLoading: false,
      selectedSession: undefined,
    };
  },
  computed: {
    dateAsStr: function () {
      return moment(this.date).format("dddd D MMMM");
    },
    dailySessions: function () {
      const daySessions = sessions[getDayNameFromDate(this.date)];
      if (daySessions === undefined) return {};
      const enrichedSessions = JSON.parse(JSON.stringify(daySessions));
      let isToday = false;
      const now = new Date();

      if (
        this.date.getYear() === now.getYear() &&
        this.date.getMonth() === now.getMonth() &&
        this.date.getDate() === now.getDate()
      )
        isToday = true;

      let dateAsStr = moment(this.date).format("MM-DD");
      for (const s of enrichedSessions) {
        const sessionData = this.daySessionsById[s.id];
        let startTimeDate = undefined;

        s.sessionId = `${s.id}-${dateAsStr}`;
        s.userIsEnrolled = false;
        s.availability = 0;
        s.isOver = false;
        if (isToday) {
          startTimeDate = dateFromSessionStartTime(s.startTime);
          if (now > startTimeDate) s.isOver = true;
        }
        if (sessionData) {
          if (sessionData.users !== undefined) {
            s.availability =
              sessionData.maxAttendees - sessionData.users.length;
            if (sessionData.users.indexOf(this.user) >= 0)
              s.userIsEnrolled = true;
          } else s.availability = sessionData.maxAttendees;
        }
      }

      return enrichedSessions;
    },
    ...mapState([
      // map this.date to store.state.date
      "date",
    ]),
  },
  methods: {
    async loadSessionsOfTheDay() {
      try {
        this.dayOff = false;
        this.isLoading = true;
        const dateAsStr = moment(this.date).format("YYYY-MM-DD");
        const daySessions = await loadDaySessions(dateAsStr);
        if (daySessions.length === 0) {
          this.dayOff = true;
          this.isLoading = false;
          return;
        }
        const byId = {};
        for (const s of daySessions) {
          byId[s.sessionId] = s;
        }
        this.daySessionsById = byId;
        this.isLoading = false;
        console.log("loadSessionsOfTheDay done");
      } catch (error) {
        console.log(`loadSessionsOfTheDay error: ${error}`);
        this.isLoading = false;
      }
    },
    async sessionModal(session) {
      const modalInstance = this.$oruga.modal.open({
        component: SessionEnroll,
        props: {
          session: session,
          date: this.date },
        trapFocus: true,
      })

      const result = await modalInstance.promise;

      if (result.reason == "enrollSuccess") {
        await this.enrollSuccess();
      } else if (result.reason == "unenrollSuccess") {
        await this.unenrollSuccess();
      } else if (result.reason == "enrollError") {
        this.enrollError(result.error);
      } else if (result.reason == "unenrollError") {
        this.unenrollError();
      } else {
        throw new Error(`Unknow sessionModal result: ${result.reason}`);
      }
    },
    notif(message, variant) {
      this.$oruga.notification.open({
        duration: 6000,
        message: message,
        position: 'bottom-right',
        variant: variant,
        closable: true,
      });
    },
    async enrollSuccess() {
      this.isLoading = true;
      try {
        console.log(`enrollSuccess called!`);
        await this.loadSessionsOfTheDay();
        this.notif("Vous êtes bien inscrit.e !", "primary");
      } catch (error) {
        console.log(`enrollSuccess error: ${error}`);
        this.notif("Erreur lors de l'inscription, réessayez plus tard !", "danger");
      } finally {
        this.isLoading = false;
      }
    },
    async unenrollSuccess() {
      this.isLoading = true;
      try {
        console.log(`enrollSuccess called!`);
        await this.loadSessionsOfTheDay();
        this.notif("Vous vous êtes bien désinscrit.e !", "primary");
      } catch (error) {
        this.notif("Erreur lors de la désinscription, réessayez plus tard !", "danger");
      } finally {
        this.isLoading = false;
      }
    },
    enrollError(error) {
      let enrollErrorMsg = "";
      if (error !== undefined) {
        if (error == "OutOfCredit")
          enrollErrorMsg = "Vous n'avez pas suffisamment de crédit !";
        else if (error == "SessionFull")
          enrollErrorMsg = "Le cours est complet !";
      }
      this.notif(`L'inscription n'a pu être réalisée ! ${enrollErrorMsg }`, "danger");
    },
    unenrollError() {
      this.notif("Erreur lors de la désinscription, réessayez plus tard !", "danger");
    },
  },
  watch: {
    date: {
      immediate: true,
      handler: async function () {
        try {
          await this.loadSessionsOfTheDay();
        } catch (error) {
          console.log(
            `watch date loadSessionsOfTheDay handler error: ${error}`,
          );
        }
      },
    },
  },
  created() {
    this.user = this.$store.state.user.email;
  },
};
</script>
<style scoped>
.my-button {
  width: 95px;
}
</style>
