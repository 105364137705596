import Toastify from "toastify-js";

export const info = (text) => {
  Toastify({
    text: text,
    duration: 7000,
    close: true,
    gravity: "bottom", // `top` or `bottom`
    position: "right", // `left`, `center` or `right`
    className: "toast-info",
  }).showToast();
};

export const success = (text) => {
  Toastify({
    text: text,
    duration: 3000,
    // destination: 'https://github.com/apvarun/toastify-js',
    // newWindow: true,
    close: true,
    gravity: "bottom", // `top` or `bottom`
    position: "right", // `left`, `center` or `right`
    // backgroundColor: 'linear-gradient(to right, #00b09b, #96c93d)',
    className: "toast-primary",
    // stopOnFocus: true // Prevents dismissing of toast on hover
    // onClick: ()=> {}
  }).showToast();
};

export const error = (err) => {
  let text;
  if (err && err.message) {
    text = err.message;
  } else if (err && err.error) {
    text = err.error;
  } else {
    text = err;
  }
  Toastify({
    text: text,
    duration: 20000,
    close: true,
    gravity: "bottom", // `top` or `bottom`
    position: "right", // `left`, `center` or `right`
    // backgroundColor: 'linear-gradient(to right, #00b09b, #96c93d)',
    className: "toast-danger",
    stopOnFocus: true, // Prevents dismissing of toast on hover
  }).showToast();
};
